#about {
  height: 100%;
  min-height: 100vh;
  font-size: 1.4rem;
  position: relative;
  background: #fafafa;
  clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 80% 95%, 0 100%);
  z-index: 5;
  background: #fafafa url(../../Images/Author_BG.jpg) center right no-repeat;
  background-attachment: fixed;
}


#about .wrapper {
  padding: 15rem 10rem 12rem;
  height: 100%;
  min-height: 100vh;
  max-width: 1400px;
  margin: 0 auto;
}

#about article {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  padding: 3rem 0;
}

#about .title {
  grid-column-end: span 4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#about .title h3 {
  font-size: 2.4rem;
}

#about .separator {
  background: #f300b4;
  width: 150px;
  height: 2px;
  margin: 1rem 0;
  padding: 0;
}

#about .subtitle {
  font-size: 1.6rem;
  text-align: center;
  color: inherit;
  padding-bottom: 1.5rem;
}

#about p {
  padding-bottom: 1.5rem;
  color: #555;
  line-height: 1.9rem;
}

#about .desc.full {
  grid-column-end: span 4;
  margin-bottom: 2rem;
}

#about .desc {
  grid-column-end: span 2;
  background: #ffffffaa;
  padding: 2rem;
  text-align: justify;
}

@media only screen and (max-width: 1149px) {
  #about article {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  #about .desc.full {
    grid-column-end: -1;
  }

  #about .desc {
    grid-column-end: -1;
  }
}
@media only screen and (max-width: 949px) {
  #about {
    clip-path: polygon(0 0, 20% 2%, 100% 0, 100% 100%, 80% 98%, 0 100%);
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: #fafafa url(../../Images/Author_BG_copy.jpg) bottom right no-repeat;
  }
}
@media only screen and (max-width: 649px) {
  #about .wrapper {
    padding: 10rem 2rem 8rem;
  }
}
