/***** Overlay Layer *****/
.menu-container > .overlay,
.menu-container.active > .overlay {
  position: absolute;
  right: 0;
  height: calc( 100vh - 120px );
  width: calc( 100vw - 120px );
  background: #fafafa;
}

.menu-container.active > .overlay {
  animation: overlay-slide-in 300ms forwards 300ms;
}

@keyframes overlay-slide-in {
  from {
    width: calc( 100vw - 120px );
  }
  to {
    width: 0;
  }
}

.menu-container > .overlay {
  animation: overlay-slide-out 300ms forwards;
}

@keyframes overlay-slide-out {
  from {
    left: 0;
    width: 0;
  }
  to {
    left: 0;
    width: calc( 100vw - 120px );
  }
}

/***** Menu Layer *****/
.menu-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #202934;
  border: 60px solid #181d23;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.menu-container::before,
.menu-container::after {
  content: '';
  position: absolute;
  width: 100%;
  min-height: 100vh;
  z-index: -1;
}

.menu-container::before {
  background: url(../../Images/Stars.svg);
}

.menu-container::after {
  background: url(../../Images/Trees.svg) bottom repeat-x;
}

.menu-container.deactive {
  animation: fade-out 600ms forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    z-index: 999;
  }
  50% {
    opacity: 1;
    z-index: 999;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

.menu-container.active {
  animation: fade-in 300ms forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    z-index: -1;
  }
  to {
    opacity: 1;
    z-index: 999;
  }
}

/***** Menu Items: Animation *****/
.menu-container ul,
.menu-container .social {
  margin-left: -80px;
  opacity: 0;
  animation: slide-out 200ms forwards;
}

.menu-container ul {
  list-style-type: none !important;
  font-size: 3rem;
}

@keyframes slide-out {
  from {
    opacity: 1;
    margin-left: 0px;
  }
  to {
    opacity: 0;
    margin-left: -80px;
  }
}

.menu-container.active ul,
.menu-container.active .social {
  animation: slide-in 300ms forwards 600ms;
}

@keyframes slide-in {
  from {
    opacity: 0;
    margin-left: -80px;
  }
  to {
    opacity: 1;
    margin-left: 0;
  }
}

/***** Menu Items: Hover Animation *****/
.menu-container ul li {
  border-left: .2rem solid transparent;
  transition: border-left 200ms;
}

.menu-container ul li a {
  font-size: 3rem; 
  padding-left: .5rem;
}

.menu-container ul li a::after {
  content: ' »';
  font-size: 2.5rem;
  color: transparent;
  transition: color 200ms;
}

.menu-container ul li a:hover::after {
  content: ' »';
  color: #f300b4;
}

.social {
  padding: 1rem 0 0 .5rem;
}

.social a {
  font-size: 1.5rem;
  padding: .2rem;
}

.menu-container a,
.menu-container a:visited {
  color: #fafafa;
}

.menu-container a:hover,
.menu-container a:active {
  color: #f300b4;
}

@media only screen and (max-width: 649px) {
  .menu-container {
    border: none;
  }

  .menu-container > .overlay,
  .menu-container.active > .overlay {
    height: 100vh;
    width: 100vw;
  }
  
  .menu-container.active > .overlay {
    animation: overlay-slide-in 300ms forwards 300ms;
  }
  
  @keyframes overlay-slide-in {
    from {
      width: 100vw;
    }
    to {
      width: 0;
    }
  }
  
  .menu-container > .overlay {
    animation: overlay-slide-out 300ms forwards;
  }
  
  @keyframes overlay-slide-out {
    from {
      left: 0;
      width: 0;
    }
    to {
      left: 0;
      width: 100vw;
    }
  }
}